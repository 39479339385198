@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/lato/Lato-Regular.ttf') format('truetype'); 
}


:root{
    --blue-color-100: #1A2F6D;
    --blue-color-80: #2b4185;
    --blue-color-60: #2b4885;
    --blue-color-40: #2b6485;
    --blue-color-20: #E3EAFF;

    --orange-color-100: #FF4514;
    --orange-color-80: #f04d20;
    --orange-color-60: #dd7a29;
    --orange-color-40: #b67036;
    --orange-color-20: #fdd996;

    --grey-color-200: #818181;
    --grey-color-150: #86929E;
    --grey-color-100: #593928;
    --grey-color-80: #4E4F4F;
    --grey-color-50: #EBEBEB;
    --grey-color-60: #606060;
    --grey-color-40: #B3B4B4;
    --grey-color-20: #E6E6E6;
    --grey-color-20-opacity: #E6E6E655;

    --background-color: #F7F7F7;
    --card-border-color: #EFF0F2;
    --card-bg-color: #FEFEFE;
    --input-border-color: #A8A8A8;

    --black-color: #060403;
    --white-color: #FFF;
    --white-color-2: #F2F2F2;
    --green-color: #08A693;
    --red-color: #f04d20;
    


}

*{
    margin: 0;
    padding: 0;
    color: var(--black-color);
    box-sizing: border-box;
    font-family: 'Lato';
}

span{
    overflow: hidden;
    text-overflow: ellipsis;
}   

h1, h2, h3, h4{
    margin: 0;
    font-style: normal;
}

.d-none{
    display: none;
}

.h1-regular{
    font-size: 64px;
    font-weight: 400;
    line-height: 72px;
}

.h1-bold{
    font-size: 64px;
    font-weight: 700;
    line-height: 72px;
}

.h2-regular{
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
}

.h2-bold{
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
}

.h3-regular {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
}

.h3-bold {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
}

.s1-regular{
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
}

.s1-bold{
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}

.s2-regular{
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
}

.s2-bold{
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.s3-regular{
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.s3-bold{
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.b1-regular{
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.b1-bold{
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
}

.b2-regular{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.b2-bold{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}


.b3-regular{
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.b3-bold{
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.c1-regular{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.c1-bold{
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent; 
}

input[type='date'], input[type='time'], input[type='month']{
    position: relative;
    padding-right: 30px !important;
}

::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 5px;
    cursor: pointer; /* Isso permite que o usuário clique na área onde o ícone do calendário estaria */
}